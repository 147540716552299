//
// Breadcrumbs
// --------------------------------------------------

.breadcrumb {
  padding: $breadcrumb-padding-vertical 0;
  margin-bottom: $line-height-computed;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: $border-radius-base;
  > li {
    display: inline-block;

    + li:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\003e";
      content: "#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
      color: $breadcrumb-color;
    }
  }

  > .active {
    color: $breadcrumb-active-color;
  }
}
