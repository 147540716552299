/*
 * Off Canvas
 * --------------------------------------------------
 */
    $width : 300px !default; 
    .row-offcanvas {
        position: relative;
        @include transition(all 0.25s ease-out);
    }
    .row-offcanvas-right {
        right:0;
    }
    .row-offcanvas-left {
       left:0;
    }
    .sidebar-offcanvas {
        position: absolute;
        top: 0;
        width:  $width;
        background: $white;
        height: 100%;
        z-index: 999;
    }
    .row-offcanvas.active {
        &:before {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            background: rgba(black, 0.75);
            content: "";
            z-index: 200;
        }
    }
   .sidebar-offcanvas  .navbar-nav > li {
        display: block !important; 
        float: none;
    }
 
.row-offcanvas {
    &.active .sidebar-offcanvas {
        display: block
    }
}
.sidebar-offcanvas{
    &.active {
        display: block;
    }
    display: none;
    background:$offcanvas-menu-bg;
    .offcanvas-heading{
        background: darken( $offcanvas-menu-bg, 10%);
        padding:10px 20px;
        > button {
            position:absolute;
            top: 10px;
            right: 10px;
            span{
                    padding: 5px;
            }
        }
        .input-group {
            margin-right: 40px;
        }
        position:relative;
    }
    .offcanvas-top { 
        background: darken( $offcanvas-menu-bg, 20%);
        padding:20px;
    }
    .navbar-header{
        display: none;
    }
    .navbar-collapse { 
        display: block;
    }
    .navbar-nav > li {
        display: block !important; 
        width: 100%;
        border-bottom: 1px solid #ddd;
        &:last-child {
            border-bottom:0;
        }
    }
    .nav > li > a {
        display: block;
       
        border-bottom: solid 1px $border-color;
    }
    .dropdown  > .dropdown-menu {
        position: relative!important;
        display: none!important;
        top: auto;
        left: auto;
        @include opacity(1);
        visibility: visible;
        min-width: 100%;
        width: 100%;
        padding:0 30px;
        background:#f2f2f2;
        box-shadow: none;
        border:0;
    }
    .nav > li > a,  .dropdown > i {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 20px
    }
    .dropdown > i { 
        display: block;
        width: 50px;
        position: absolute;
        right: 0;
        z-index: 99;
        text-align: center;
        line-height: 20px;
        background:$nocolor;
        color: $gray;
    }
    .active {
        > a.dropdown-toggle {
            color: $warning;
        }
        > .dropdown-menu  {
            display: block!important;
            padding:0 30px 20px;
            border-top: 1px solid #ddd;
            
            .list-unstyled {
                li {
                    border-bottom: 1px solid #ddd;
                    padding:$padding-base-vertical 0;
                }
            }
        }
    }
}
